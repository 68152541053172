<script lang="ts">
  import { Drawer } from "vaul-svelte";
  import Icon, { type IconName } from "@elements/Icon.svelte";
  import { openSearchModal } from "src/stores/modalStore";

  export let iconName: IconName | null = null;
  export let title: string;
  export let links: any;

  let isDrawerOpen = false;

  function openSearch() {
    isDrawerOpen = false;
    openSearchModal();
  }
</script>

<Drawer.Root bind:open={isDrawerOpen}>
  <li class="tracking-wide">
    <Drawer.Trigger
      class="p-4 text-body-small text-white stroke-white hover:text-zinc-300 flex flex-col items-center gap-1"
    >
      {#if iconName}
        <Icon name={iconName} />
      {/if}{title}
    </Drawer.Trigger>
  </li>
  <Drawer.Portal>
    <Drawer.Overlay class="fixed inset-0 bg-black/40" />
    <Drawer.Content
      class="z-50 fixed bottom-0 left-0 right-0 mt-24 flex h-[70%] flex-col rounded-t-[10px]"
    >
      <div class="flex-1 rounded-t-[10px] bg-matt-black pt-6 px-10">
        <Drawer.Close
          class="block text-white mx-auto mb-8 h-1 w-16 flex-shrink-0 rounded-full
        bg-s500"
        ></Drawer.Close>

        <ul class="list-none p-0 flex flex-col justify-between gap-4">
          <button
            on:click={openSearch}
            class="font-light tracking-wider stroke-white inline-flex items-center gap-2 border-white border rounded-full px-5 py-2 backdrop-blur-sm bg-white/30 text-white lg:w-64 text-left"
          >
            <Icon name="search" size="17px" /> Search
          </button>
          <hr class="border-t border-white opacity-20 w-full h-1 my-2" />

          {#each links as link}
            <a
              href={link.url}
              class="inline-flex items-center gap-2 stroke-white fill-white block text-white text-body-large font-bold tracking-wide mb-4 border-transparent focus:border-transparent focus:ring-0"
            >
              {#if link.iconName}
                <Icon name={link.iconName} class="mr-4" />
              {/if}

              {link.title}
            </a>
          {/each}
        </ul>
      </div>
    </Drawer.Content>
  </Drawer.Portal>
</Drawer.Root>
