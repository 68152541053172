<script lang="ts" context="module">
  /**
   * Module Imports
   * ---------------------------------------------------------------------------
   */

  import Icon, { type IconName } from "@elements/Icon.svelte";

  /**
   * Module Exports
   * ---------------------------------------------------------------------------
   */

  export interface NavItemProps {
    title: string;
    id?: string;
    url?: string;
    links?: NavItemProps[];
    bottomLink?: NavItemProps;
    subLinks?: NavItemProps[];
    iconName?: IconName;
    featuredMediaItem?: {
      seriesTitle: string;
      title: string;
      url: string;
      imageUrl: string;
    };
    onlyUnderMobileTab?: "more";
    underMobileTab?: "more";
    mobileTitle?: string;
    order?: number;
    variant?: "primary" | "secondary";
  }
</script>

<script lang="ts">
  /**
   * Imports
   * ---------------------------------------------------------------------------
   */

  import { activeSubmenu } from "./navigationStore.ts";
  import { twJoin } from "tailwind-merge";

  /**
   * Props
   * ---------------------------------------------------------------------------
   */

  export let title: string;
  export let id: string | undefined = undefined;
  export let url: string | null = null;
  export let links: NavItemProps[] = [];
  export let variant: "primary" | "secondary" = "primary";
  export let iconName: IconName | undefined = undefined;

  /**
   * Styles
   * ---------------------------------------------------------------------------
   */

  const variantStyles = {
    primary: "uppercase text-white hover:text-p300",
    secondary: twJoin(
      "inline-flex tracking-even-wider bg-none border-none",
      "stroke-black fill-black text-black focus:text-p300",
      "hover:text-p300 hover:stroke-p300 hover:fill-p300", // hover
    ),
  };
  const secondaryVariantIconStyles = "inline-flex ml-2";

  /**
   * Data
   * ---------------------------------------------------------------------------
   */

  let navItemElement: HTMLButtonElement | HTMLAnchorElement | null = null;
  let hoverTimeoutId: any;
  let dismissalTimeoutId: any;
  const isPrimary = variant === "primary";
  const hasSubmenu = links && links.length > 0;

  /**
   * Mouse Handlers
   * ---------------------------------------------------------------------------
   */

  const onMouseEnter = () => {
    // primary NavItems only
    if (!id || !isPrimary) {
      return;
    }
    hoverTimeoutId = setTimeout(() => {
      activeSubmenu.set({ id, isMouseInBounds: true }); // set active submenu
    }, 300);
  };

  const onMouseLeave = () => {
    // primary NavItems only
    if (!id || !isPrimary) {
      return;
    }
    clearTimeout(hoverTimeoutId);
    if (hasSubmenu) {
      activeSubmenu.setKey("isMouseInBounds", false);
    } else {
      activeSubmenu.set({ id: "" });
    }
  };

  /**
   * Keyboard Handlers
   * ---------------------------------------------------------------------------
   */

  const onKeyPress = (event: KeyboardEvent) => {
    if (
      !!id &&
      (event.code === "Enter" ||
        event.code === "Space" ||
        event.code === "Escape")
    ) {
      if (event.code === "Escape" || $activeSubmenu.id === id) {
        activeSubmenu.set({ id: "" }); // close submenu
      } else {
        activeSubmenu.set({ id, isMouseInBounds: true }); // open submenu
      }
    }
  };

  /**
   * Dismissal Timeout
   * ---------------------------------------------------------------------------
   */

  /** clear the dismissal timeout, if set */
  const clearDismissalTimeout = () => {
    if (dismissalTimeoutId) {
      clearTimeout(dismissalTimeoutId);
      dismissalTimeoutId = null;
    }
  };

  // respond to changes in $activeSubmenu state
  $: (() => {
    // primary NavItems only
    if (!id || !isPrimary) {
      return;
    }
    if ($activeSubmenu.id === id) {
      // this nav item is active
      if ($activeSubmenu.isMouseInBounds) {
        // mouse in bounds
        clearDismissalTimeout();
      } else {
        // mouse not in bounds
        clearDismissalTimeout();
        dismissalTimeoutId = setTimeout(() => {
          activeSubmenu.set({ id: "" }); // close submenu
        }, 500);
      }
    } else {
      // this nav item is not active
      clearDismissalTimeout();
    }
  })();
</script>

<!-- Template ----------------------------------------------------------------->

{#if url}
  <a
    bind:this={navItemElement}
    on:mouseenter={onMouseEnter}
    on:mouseleave={onMouseLeave}
    tabindex={$$restProps.tabindex}
    class={variantStyles[variant]}
    href={url}
    id={`button_${id}`}
    {...$$restProps}
  >
    {title}
    {#if iconName && variant === "secondary"}
      <Icon name={iconName} class={secondaryVariantIconStyles} />
    {/if}
  </a>
{:else}
  <button
    bind:this={navItemElement}
    tabindex={$$restProps.tabindex}
    aria-haspopup="true"
    aria-expanded={$activeSubmenu.id === id}
    aria-controls={id}
    id={`button_${id}`}
    class={variantStyles[variant]}
    on:mouseenter={onMouseEnter}
    on:mouseleave={onMouseLeave}
    on:click|stopPropagation={() => {
      // capture this click and do nothing; this prevents the click from
      // being seen by the submenu "outsideclick" handler
    }}
    on:keypress|stopPropagation|preventDefault={onKeyPress}
    {...$$restProps}
    >{title}
    {links && links.length ? "+" : ""}
    {#if iconName && variant === "secondary"}
      <Icon name={iconName} class={secondaryVariantIconStyles} />
    {/if}
  </button>
{/if}
