import { atom, map } from "nanostores";

export interface ActiveSubmenu {
  /** the ID of the active submenu element; an empty string if none active */
  id: string;
  /** true if the mouse is in bounds of the submenu or its nav item */
  isMouseInBounds?: boolean;
}

export const activeSubmenu = map<ActiveSubmenu>({ id: "" });
export const activeTopmenu = atom("");
export const featuredMediaItem = atom(null);
