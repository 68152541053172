<script lang="ts">
  import { appendIds } from "./Navigation.svelte";
  import NavItem from "./NavItem.svelte";
  import { activeSubmenu } from "./navigationStore";
  export let isActive = false;
  export let activeLink: any = [];
  import MediaItemSquare from "../content/ContentSquare.svelte";
  import { clickOutside } from "../../svelte/actions/clickOutside.ts";
  import { twJoin } from "tailwind-merge";

  /** true if this submenu is active (visible) */
  $: isActive = $activeSubmenu.id === activeLink.id;
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<div
  class={twJoin(
    "transition-all duration-200 transform",
    isActive
      ? "translate-y-2 opacity-100 visible"
      : "translate-y-7 opacity-0 invisible",
  )}
  style="
    position:absolute;
            width: 0;
            height: 0;
            border-left: 25px solid transparent;
            border-right: 25px solid transparent;
            border-bottom: 25px solid white;
            "
  on:mouseenter={() => {
    if (isActive) {
      activeSubmenu.setKey("isMouseInBounds", true);
    }
  }}
  on:mouseleave={() => {
    if (isActive) {
      activeSubmenu.setKey("isMouseInBounds", false);
    }
  }}
  on:click|stopPropagation={() => {
    // capture this click and do nothing; this prevents the click from
    // being seen by the submenu "outsideclick" handler
  }}
  role="navigation"
/>

<svelte:window
  on:keyup={(event) => {
    switch (event.key) {
      case "Escape":
        document.getElementById(`button_${$activeSubmenu.id}`)?.focus();
        activeSubmenu.set({ id: "" });
        break;
      default:
        break;
    }
  }}
/>

<div
  use:clickOutside
  on:outsideclick={() => {
    if (isActive) {
      activeSubmenu.set({ id: "" }); // close the submenu
    }
  }}
  on:mouseenter={() => {
    if (isActive) {
      activeSubmenu.setKey("isMouseInBounds", true);
    }
  }}
  on:mouseleave={() => {
    if (isActive) {
      activeSubmenu.setKey("isMouseInBounds", false);
    }
  }}
  id={activeLink.id}
  class={twJoin(
    "left-0 w-full absolute h-0 mt-6 transition-all duration-200 transform",
    isActive
      ? "translate-y-2 opacity-100 visible"
      : "translate-y-7 opacity-0 invisible",
  )}
  role="navigation"
>
  <div
    class="flex max-w-[909px] m-auto rounded-2xl px-14 py-10 bg-white text-black"
  >
    <ul class="list-none w-2/3 relative pl-0">
      {#if activeLink.links && activeLink.links.length > 0}
        {#each appendIds(activeLink.links) as link, i}
          <li class="mb-3">
            <NavItem
              id={`${activeLink.id}_${i}`}
              title={link.title}
              url={link.url}
              variant="secondary"
            />
          </li>
        {/each}
      {/if}
      {#if activeLink.bottomLink}
        <li class="absolute bottom-0 border-t pr-48 pt-4">
          <NavItem
            id={`${activeLink.id}-bottomLink`}
            title={activeLink.bottomLink.title}
            url={activeLink.bottomLink.url}
            variant="secondary"
            iconName={"arrow-right"}
          />
        </li>
      {/if}
    </ul>
    {#if activeLink.featuredMediaItem}
      <MediaItemSquare props={activeLink.featuredMediaItem} />
    {/if}
  </div>
</div>
